import React, { useState, useEffect } from "react";
import ScreenHeading from "../../utilities/ScreenHeading/ScreenHeading";
import ScrollService from "../../utilities/ScrollService";
import Animations from "../../utilities/Animations";
import "./Resume.css";
import educationLogo from "../../assets/Resume/education.svg";
import interestLogo from "../../assets/Resume/interests.svg";
import programmingLogo from "../../assets/Resume/programming-skills.svg";
import projectsLogo from "../../assets/Resume/projects.svg";
import workLogo from "../../assets/Resume/work-history.svg";



const Resume = (props) => {
  /* STATES */
  const [selectedBulletIndex, setSelectedBulletIndex] = useState(0);
  const [carousalOffsetStyle, setCarousalOffsetStyle] = useState({});

  let fadeInScreenHandler = (screen) => {
    if (screen.fadeInScreen !== props.id) return;

    Animations.animations.fadeInScreen(props.id);
  };
  const fadeInSubscription =
    ScrollService.currentScreenFadeIn.subscribe(fadeInScreenHandler);

  /* REUSABLE MINOR COMPONENTS */
  const ResumeHeading = (props) => {
    return (
      <div className="resume-heading">
        <div className="resume-main-heading" >
          <div className="heading-bullet"></div>

          <span>{props.heading ? props.heading : ""}</span>
          {props.fromDate && props.toDate ? (
            <div className="heading-date">
              {props.fromDate + "-" + props.toDate}
            </div>
          ) : (
            <div></div>
          )}

        </div>
        <div className="resume-sub-heading">
          <span>{props.subHeading ? props.subHeading : ""}</span>
        </div>
        <div className="resume-heading-description">
          <span>{props.description ? props.description : ""}</span>
        </div>
      </div>
    );
  };

  /* STATIC RESUME DATA FOR THE LABELS*/
  const resumeBullets = [
    { label: "Education", logoSrc: educationLogo },
    { label: "Work History", logoSrc: workLogo },
    { label: "Programming Skills", logoSrc: programmingLogo },
    { label: "Projects", logoSrc: projectsLogo },
    { label: "Interests", logoSrc: interestLogo},
  ];

  //here we have
  const programmingSkillsDetails = [
    { skill: "JavaScript", ratingPercentage: 85 },
    { skill: "React JS", ratingPercentage: 85 },
    { skill: "React Native", ratingPercentage: 65 },
    { skill: "Express JS", ratingPercentage: 89 },
    { skill: "Node JS", ratingPercentage: 89 },
    { skill: "Mongo Db", ratingPercentage: 65 },
    { skill: "C++", ratingPercentage: 80 },
    { skill: "Data Structures", ratingPercentage: 85 },
    { skill: "Java", ratingPercentage: 80 },
  ];

  const projectsDetails = [
    {
      title: "Creating Trails",
      duration: { fromDate: "2024", toDate: "2024" },
      description:
        "Led the development of a React Native and Springboot app that enabled users to plan itineraries. The app reduced time spent on planning by 40% for users.",
      subHeading: "NextJs, NodeJs, SQL, Tailwind",
     
    },
    {
      title: "Highonranking",
      duration: { fromDate: "2023", toDate: "2024" },
      description:
        "A platform built for building community for readers and writers across various domains.",
      subHeading: "NextJs, NodeJs, SQL, Tailwind",
     
    },
    {
      title: "RESTAURANT MOBILE APP ",
      duration: { fromDate: "2020", toDate: "2021" },
      description:
        "Developed a React and Springboot-based app to streamline order management, resulting in a 20% increase in order processing speed. Utilized DynamoDB for efficient data storage.",
      subHeading:
        "React Native, Mongo DB, Express Js, Node Js, Redux.",
    },
    {
      title: "Digitally Inclined Ecommerce",
      duration: { fromDate: "2019", toDate: "2020" },
      description:
        "MERN stack-based platform to sell NFTs and online goods.        ",
      subHeading:
        "MERN stack, Mailchimp, Tailwind",
    },
  ];

  const workExperience = [
    {
      title: "Yatra Online Limited, Gurgaon HR",
      duration: { fromDate: "2024", toDate: "present" },
      description:
        "Working as Software Engineer at Yatra Flights, primarily Tech Stack- Java, Springboot.",
      subHeading: "Software Development Engineer",
     
    },
    {
      title: "Stampmyvisa, Bengaluru KA",
      duration: { fromDate: "2023", toDate: "2024" },
      description:
        "Working on Frontend using React for B2B Product",
      subHeading: "Full Stack Developer",
     
    },
    {
      title: "CRED, Bengaluru KA",
      duration: { fromDate: "2022", toDate: "2023" },
      description:
        "Developed an app for CRED pay merchants to test CRED pay using React Native and Node. Automated onboarding and legal agreement contracts, enhancing efficiency and reduced cost, using Python. Collaborating with the product, and business teams, focused on tech-driven problem-solving and automation.",
      subHeading:
        "Software Engineer",
    },
    {
      title: "StartwithGenesis, Ghaziabad UP",
      duration: { fromDate: "2019", toDate: "2020" },
      description:
        "Worked on Frontend Development of D2C product to hire freelancers for industrial projects. ",
      subHeading:
        "Web Developer",
    },
  ];

  const resumeDetails = [
    <div className="resume-screen-container" key="education">
      <ResumeHeading
        heading={"KIET GROUP OF INSTITUTIONS | AKTU, Lucknow IN"}
        subHeading={"B.Tech in Electronics and Communication Engineering |  CGPA:8.37"}
        fromDate={"2019"}
        toDate={"2023"}
      />

      <ResumeHeading
        heading={"Rosy Public School | Board: C.B.S.E."}
        subHeading={"12th (PCM) 85.6%"}
        fromDate={"2017"}
        toDate={"2018"}
      />
      <ResumeHeading
        heading={"St. Anthony's Senior Secondary School | Board: C.B.S.E "}
        subHeading={"10th | CGPA: 9.6"}
        fromDate={"2015"}
        toDate={"2016"}
      />
    </div>,

    /* WORK EXPERIENCE */

    <div className="resume-screen-container" key="projects">
      {workExperience.map((projectsDetails, index) => (
        <ResumeHeading
          key={index}
          heading={projectsDetails.title}
          subHeading={projectsDetails.subHeading}
          description={projectsDetails.description}
          fromDate={projectsDetails.duration.fromDate}
          toDate={projectsDetails.duration.toDate}
          
        />
      ))}
    </div>

    // <div className="resume-screen-container" key="work-experience">
    //   <div className="experience-container">
    //     <ResumeHeading
    //       heading={"Startwithgenesis"}
    //       subHeading={"WEBSITE OPTIMIZATION ANALYST INTERN"}
    //       fromDate={"2019"}
    //       toDate={"2020"}
    //     />
    //     <div className="experience-description">
    //       <span className="resume-description-text">
    //      - Optimized Webpages loading time by nearly 40%.
    //       </span>
    //     </div>
    //     <div className="experience-description">
    //       <span className="resume-description-text">
    //      - Used Web Technologies like Javascript, HTML, and various SEO Tools for decreasing website loading time and optimizing results.
    //       </span>
        
    //       <br />
    //     </div>
    //   </div>
    // </div>
    ,

    /* PROGRAMMING SKILLS */
    <div
      className="resume-screen-container programming-skills-container"
      key="programming-skills"
    >
      {programmingSkillsDetails.map((skill, index) => (
        <div className="skill-parent" key={index}>
          <div className="heading-bullet"></div>
          <span>{skill.skill}</span>
          <div className="skill-percentage">
            <div
              style={{ width: skill.ratingPercentage + "%" }}
              className="active-percentage-bar"
            ></div>
          </div>
        </div>
      ))}
    </div>,

    /* PROJECTS */
    <div className="resume-screen-container" key="projects">
      {projectsDetails.map((projectsDetails, index) => (
        <ResumeHeading
          key={index}
          heading={projectsDetails.title}
          subHeading={projectsDetails.subHeading}
          description={projectsDetails.description}
          fromDate={projectsDetails.duration.fromDate}
          toDate={projectsDetails.duration.toDate}
          
        />
      ))}
    </div>,

    /* Interests */
    <div className="resume-screen-container" key="interests">
      <ResumeHeading
        heading="Content Creation"
        description="When I'm not coding, you'll find me behind a lens, capturing moments and views. I love creating content that brings those experiences to life! 📸"
      />
      <ResumeHeading
        heading="Books"
        description="I absolutely love diving into books, especially Hindi fiction by authors like Manav Kaul. And when it comes to personal growth, self-help books are my jam! 📚"
      />
      <ResumeHeading
        heading="Music"
        description="I'm on a musical journey, with hundreds of failed attempts at learning guitar under my belt - but hey, I'm still strumming! 🎸"
      />
    </div>,
  ];

  const handleCarousal = (index) => {
    let offsetHeight = 380;

    let newCarousalOffset = {
      style: { transform: "translateY(" + index * offsetHeight * -1 + "px)" },
    };

    setCarousalOffsetStyle(newCarousalOffset);
    setSelectedBulletIndex(index);
  };

  const getBullets = () => {
    return resumeBullets.map((bullet, index) => (
      <div
        onClick={() => handleCarousal(index)}
        className={
          index === selectedBulletIndex ? "bullet selected-bullet" : "bullet"
        }
        key={index}
      >
        <img
          className="bullet-logo"
          src={bullet.logoSrc}
          alt="icon"
        />
        <span className="bullet-label">{bullet.label}</span>
      </div>
    ));
  };

  const getResumeScreens = () => {
    return (
      <div
        style={carousalOffsetStyle.style}
        className="resume-details-carousal"
      >
        {resumeDetails.map((ResumeDetail) => ResumeDetail)}
      </div>
    );
  };

  useEffect(() => {
    return () => {
      /* UNSUBSCRIBE THE SUBSCRIPTIONS */
      fadeInSubscription.unsubscribe();
    };
  }, [fadeInSubscription]);

  return (
    <div
      className="resume-container screen-container fade-in"
      id={props.id || ""}
    >
      <div className="resume-content">
        <ScreenHeading title={"Resume"} subHeading={"My Overall Professional Details"} />
        <div className="resume-card">
          <div className="resume-bullets">
            <div className="bullet-container">
              <div className="bullet-icons"></div>
              <div className="bullets">{getBullets()}</div>
            </div>
          </div>

          <div className="resume-bullet-details">{getResumeScreens()}</div>
        </div>
      </div>
    </div>
  );
};

export default Resume;
