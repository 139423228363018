import React from 'react';
import Typical from 'react-typical';
import GlitchText from 'react-glitch-effect/core/GlitchText';
import './Profile.css';

export default function Profile() {
    return (
        <div className="profile-container">
            <div className="profile-parent">
                <div className="profile-details">
                    <div className="profile-details-name">
                        <span className="primary-text">
                            Hey, How you doin'?
                          
                        </span>
                    </div>
                    <div>
                    <GlitchText
                                onHover={true}
                                color1="rgba(255, 88, 35, 1)"
                                color2="rgba(26,134,2,1)"
                            >
                                <h1 className='name'>I'm Abhinav Dixit </h1>
                            </GlitchText>
                            </div>
                    <div className="profile-details-role">
                        <span className="primary-text">
                            <h2>
                                
                                <Typical
                                    loop={Infinity}
                                    steps={[
                                        "I'm a software Engineer 👨🏻‍💻",
                                        3000,
                                        "I usually code in Javascript, promise 🤞",
                                        3000,
                                        "Loves travelling and good food ❤️",
                                        3000,
                                    ]}
                                />
                            </h2>
                        </span>
                    </div>
                    <div className="profile-options">
                        <a href="https://highonterm.abhinavdixit.com/" target="_blank" rel="noopener noreferrer">
                            <button className="btn primary-btn">
                                HighOnTerm
                            </button>
                        </a>
                        <a href="abhinavdixit.pdf" download="Abhinav Dixit Resume.pdf">
                            <button className="btn btn-sec">Get Resume</button>
                        </a>
                    </div>
                </div>
                <div className="colz">
                    <div className="colz-icon">
                        <a href="https://www.linkedin.com/in/highonranking/" aria-label="LinkedIn">
                            <i className="fa fa-linkedin-square"></i>
                        </a>
                        <a href="https://twitter.com/highonranking" aria-label="Twitter">
                            <i className="fa fa-twitter"></i>
                        </a>
                        <a href="https://github.com/highonranking" aria-label="GitHub">
                            <i className="fa fa-github-square"></i>
                        </a>
                        <a href="mailto:abhinavdixit2306@gmail.com" aria-label="Email">
                            <i className="fa fa-envelope-square"></i>
                        </a>
                        <a href="tel:+918528723582" aria-label="Phone">
                            <i className="fa fa-phone-square"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}
